.title-schedule-section {
  margin-bottom: 1rem;
  /* headline-sm/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */

  /* Base/Primary */
  color: #1e1e1f;
  padding: 0.5rem;
}

.card-unit-jadwal {
  flex: 1;
  /* max-width: 200px;
    min-width: 200px; */
  border-radius: 12px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  /* height: 248px; */
  margin-bottom: 16px;
  margin-left: 1px !important;
  background: #ffffff;
}

.card-unit-jadwal.live {
  border: 2px solid #cf1701;
}

.card-unit-jadwal-header {
  height: 64px;
  width: 100%;
  border-radius: 8px 8px 0px 0;
  background: #ffffff;
}

.card-unit-tag {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  gap: 9px;
  min-width: 200px;
  min-height: 22px;
  /* height: 26px; */
}

.card-unit-tag > div {
  width: 60%;
}

.card-unit-tag > img {
  width: 40%;
  position: absolute;
  right: 0;
}

.card-unit-jadwal-image {
  height: 100%;
  max-width: 128px;
  float: right;
  right: 0;
  bottom: 0;
  border-radius: 8px 8px 0px 0;
  /* background: #F0F2F5; */
}

.card-unit-jadwal-body {
  padding: 12px;
  /* height: 184px; */
}

.card-unit-jadwal-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #092540;
  text-transform: uppercase;
}

.card-unit-jadwal-date {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #092540;
  margin-bottom: 10px;
}

.card-unit-jadwal-time {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #092540;
  margin-bottom: 8px;
}

.card-unit-jadwal-location {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #092540;
}

.card-unit-price-detail {
  justify-content: space-between;
  height: 28px;
  align-items: center;
  margin-bottom: 8px;
  display: flex;
}

.card-unit-ppn {
  border-radius: 6px;
  color: #ffffff;
  background: #cf1701;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding: 4px 8px;
  position: relative;
}

.icon-ppn {
  margin-right: 6px;
}

.card-unit-spec {
  /*display: flex;*/
  border-top: 1px solid #f0f2f5;
  padding: 8px 0px;
  font-family: "Source Sans Pro";
}

.card-unit-spec-detail {
  display: grid;
  flex: 50;
}

.card-unit-spec-detail > #header {
  color: var(--base-secondary, #646566);
  font-size: 13px;
  line-height: 15px;
}

.card-unit-spec-detail > #detail {
  color: var(--base-label, #3c3c3d);
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}

.card-unit-jadwal-time > span {
  display: table-cell;
  vertical-align: middle;
  margin-left: 10px;
}

.card-unit-jadwal-location > span {
  display: table-cell;
  vertical-align: middle;
  padding-left: 10px !important;
}

.card-unit-jadwal-footer {
  padding-top: 8px;
  border-top: 1px solid #f0f2f5;
  color: #cf1701;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.tag-filter-jadwal {
  margin-top: 20px;
  margin-bottom: 20px;
}

.tag-filter-jadwal > .tag {
  padding: 2px 10px;
  background-color: #f0f2f5;
  margin-right: 8px;
  border-radius: 6px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.react-datepicker {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(61, 75, 92, 0.12),
    0px 0px 1px rgba(61, 75, 92, 0.05) !important;
  border-radius: 12px !important;
  border: none !important;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 20px !important;
  float: right !important;
  margin-top: 26px;
  border: 1px solid rgb(245, 245, 245) !important;
}

.react-datepicker__navigation {
  margin-top: 25px;
}

.react-datepicker__navigation--previous {
  left: 20px !important;
}

.react-datepicker__navigation--next {
  background: url(../images/rightArrow.png) no-repeat !important;
  /* width: 15px; */
  height: 15px;
  border: none;
}

.react-datepicker__navigation--next > span {
  display: none !important;
}

.react-datepicker__navigation--previous {
  background: url(../images/leftArrow.png) no-repeat !important;
  width: 15px;
  height: 15px;
  border: none;
}

.react-datepicker__navigation--previous > span {
  display: none !important;
}

.react-datepicker__header {
  text-align: center;
  background-color: #ffffff !important;
  border-bottom: none !important;
  padding: 8px 0;
  position: relative;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
  border-bottom: 1px solid #e4e7eb !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.react-datepicker__current-month {
  text-transform: uppercase !important;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Base/Primary */

  color: #092540;
}

.react-datepicker__day-names > .react-datepicker__day-name {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #9aa2b1 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #fae8e6 !important;
  color: #cf1701 !important;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem;
  border: 1px solid #cf1701 !important;
  background-color: #ffffff !important;
  color: #092540 !important;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.filterUnitLelang {
  left: 0px;
  top: 104px;

  /* Base/White */

  background: #ffffff;
  /* Light/60% */

  box-shadow: 0px 4px 8px rgba(61, 75, 92, 0.12),
    0px 0px 1px rgba(61, 75, 92, 0.05);
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.box-filter {
  /* Base/White */

  background: #ffffff;
  /* Base/Inline */

  border: 1px solid #d1d5dc;
  border-radius: 12px;
  padding: 8px;
  margin-bottom: 10px;
}

.filter-type {
  border-left: none !important;
  border-top: none !important;
  border-bottom: none !important;
  max-width: 138px !important;
}

.filter-search {
  border-right: none !important;
  border-top: none !important;
  border-bottom: none !important;
}

.filter-button {
  /* Primary/Icon */

  background: #cf1701;
  /* Light/20% */

  box-shadow: 0px 0.5px 2px rgba(61, 75, 92, 0.12),
    0px 0px 1px rgba(61, 75, 92, 0.05);
  border-radius: 8px !important;
  padding: 10px 28px;
}

.filter-icon {
  margin-left: 12px !important;
}

.filter-options {
  display: flex;
  grid-gap: 10px;
}

.filter-item {
  flex: 1;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Primary */

  color: #092540;
}

.tag-filter-unit-lelang {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #d1d5dc;
}

.tag-filter-unit-lelang > span:first-child {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #687083;
  margin-right: 10px;
}

.tag-filter-unit-lelang > .tag {
  background: #f0f2f5;
  border-radius: 12px;
  padding: 16px;
  margin-right: 10px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Base/Primary */

  color: #092540;
}

.order-data {
  display: table;
  width: 100%;
}

.order-data > div,
.form-select {
  display: table-cell;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Primary */
  color: #092540;
}

.search-result-total {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Primary */
  color: #092540;
}

.card-unit {
  flex: 1;
  /* Base/White */

  background: #ffffff;
  /* Light/40% */

  box-shadow: 0px 2px 4px rgba(61, 75, 92, 0.12),
    0px 0px 1px rgba(61, 75, 92, 0.05);
  border-radius: 12px;
  /* margin-bottom: 20px;
    margin-right: 10px !important; */
}

.card-unit-body {
  clear: both;
  padding: 12px;
}

.card-unit-name {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  /* Base/Secondary */
  color: #687083;
  height: 30px;
}

.card-unit-tag > span {
  /* Caption 12/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  /* Base/Label */
  color: #1a3650;
  padding: 1px 8px;
  /* margin-right: 8px; */

  /* Base/Light */

  background: #f0f2f5;
  border-radius: 6px;
}

.card-unit-price {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  /* Base/Primary */
  color: #092540;
  padding-top: 8px;
  padding-bottom: 8px;
}

.card-unit-footer {
  margin-top: 8px;
  border-top: 1px solid #f0f2f5;
  padding-top: 8px;
}

.card-unit-footer > .time-location {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  /* Base/Secondary */
  color: #687083;
  display: table - cell;
  vertical-align: middle;
  margin-right: 10px;
}

.card-unit-footer > .metode {
  float: right;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  /* Base/Secondary */
  color: #687083;
  align-items: center;
  padding: 0px 4px;
  gap: 8px;

  /* Base/Secondary */
  border: 1px solid #687083;
  border-radius: 6px;
  height: 20px;
}

.card-unit-footer > .metode.red {
  border: 1px solid red;
  color: red;
  height: auto;
  text-align: center;
}

.card-unit-footer > .metode.blue {
  border: 1px solid blue;
  color: blue;
  height: auto;
  text-align: center;
}

.footer-label {
  justify-content: space-between;
  /* flex-wrap: wrap; */
  align-items: center;
  position: relative;
}

.card-unit-header {
  position: relative;
  background-position: center !important;
  /* Center the image */
  background-repeat: no-repeat !important;
  /* Do not repeat the image */
  background-size: cover !important;
  /* Resize the background image to cover the entire container */
  background: #f0f2f5;
  width: 100%;
  height: 215px;
  border-radius: 8px 8px 0px 0;
}

.card-unit-header .overlay {
  height: 56px;
  border-radius: 8px 8px 0px 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.card-unit-grade {
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 4px 8px;

  /* Base/White */

  background: #ffffff;
  /* Base/Outline */

  border: 1px solid #dcdde0;
  border-radius: 8px;
  /* Text 16/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: right;

  /* Primary/Icon */

  color: #c20102;
}

.card-unit-lot {
  float: left;
  color: #ffffff;
  background: #cf1701;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  padding: 3px 10px;
  border-bottom-right-radius: 5px;
  position: relative;
  z-index: 2;
}

.card-unit-lot:after {
  content: "";
  border-top: 26px solid #cf1701;
  border-right: 15px solid transparent;
  position: absolute;
  top: 0;
  right: -15px;
}

.card-unit-lot.c-orange {
  background: #ff6a39;

  left: -3px;
  z-index: 1;
  padding-left: 13px;
}

.card-unit-lot.c-orange:after {
  border-top: 26px solid #ff6a39;
  /* right: -5px; */
}
.unit-lot-wrapper {
  position: relative;
}
.icon-nipl {
  padding: 0 5px;
}
.card-unit-image {
  width: 100%;
  right: 0;
  bottom: 0;
  border-radius: 8px 8px 0px 0;
  background: #f0f2f5;
}

.tag-filter-unit {
  margin-top: 20px;
  margin-bottom: 20px;
}

.tag-filter-unit > .tag {
  align-items: center;
  padding: 4px 12px;
  gap: 8px;
  width: 72px;
  height: 28px;

  /* Base/Inline */
  border: 1px solid #d1d5dc;
  margin-right: 8px;
  border-radius: 6px;
  /* Text 14/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Label */
  color: #1a3650;
}

.pagination {
  display: table;
  font-family: "Source Sans Pro" !important;
  /* Text 14/Regular */

  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  float: right;
  color: #000000;
  margin-bottom: 20px;
}

.pagination > div {
  display: table-cell;
  vertical-align: middle;
  padding-right: 8px;
}

.pagination > div > input.page {
  padding: 10px 12px;
  gap: 8px;
  width: 76px;
  height: 40px;

  /* Base/White */
  background: #ffffff;
  /* Base/Inline */
  border: 1px solid #d1d5dc;
  border-radius: 8px;
}

.pagination > div > ul {
  display: table-cell;
  list-style-type: none;
  vertical-align: middle;
  padding: 0;

}

.pagination > div > ul > li {
  display: table-cell;
}

span.next {
  /* Primary/Icon */
  padding: 8px;
  background: #cf1701;
  /* Light/20% */

  box-shadow: 0px 0.5px 2px rgba(61, 75, 92, 0.12),
    0px 0px 1px rgba(61, 75, 92, 0.05);
  border-radius: 0px 8px 8px 0px;
}

span.previous {
  /* Primary/Icon */
  padding: 8px;
  background: #cf1701;
  /* Light/20% */

  box-shadow: 0px 0.5px 2px rgba(61, 75, 92, 0.12),
    0px 0px 1px rgba(61, 75, 92, 0.05);
  border-radius: 8px 0px 0px 8px;
}

.unit-footer {
  display: block !important;
  height: 70px;
  bottom: 0;
  /* Base/Primary */

  background: #ffffff;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 24px 24px 0px 0px;
  border: none;
  z-index: 2;
}

.unit-footer .unit-image {
  background-position: center !important;
  /* Center the image */
  background-repeat: no-repeat !important;
  /* Do not repeat the image */
  background-size: cover !important;
  /* Resize the background image to cover the entire container */
  background: #f0f2f5;
  border-radius: 8px;
  height: 50px;
  width: 70px;
  margin-right: 20px;
}

.unit-footer .unit-name {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Base/White */
  color: #1e1e1f;
  display: block;
}

.unit-footer .nomor-lot {
  padding: 1px 8px;
  /* Primary/Icon */

  background: #cf1701;
  border-radius: 6px;
  /* Caption 12/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  /* Base/White */
  color: #ffffff;
}

.unit-footer a {
  text-decoration: none;
}

.button-footer-unit {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  text-align: center;
  /* Base/White */
  /* color: #C20102; */
  padding: 12px 28px;
  margin-left: 8px;
  background: #f9e6e6;
  border-radius: 8px;
}

.button-footer-unit.live-auction {
  /* Primary/Icon */
  background: #cf1701;
  /* Light/20% */
  box-shadow: 0px 0.5px 2px rgba(61, 75, 92, 0.12),
    0px 0px 1px rgba(61, 75, 92, 0.05);
  border-radius: 8px;
  color: #ffffff;
}

.button-footer-unit.proxy-bid,
.button-footer-unit.beli-nipl {
  border: 1px solid #ffffff;
  border-radius: 8px;
}

.button-footer-unit-small {
  padding: 14px;
  /* Base/White */
  /* Base/White */

  background: #ffffff;
  /* Primary/Inline */

  border: 1px solid #e18081;
  /* Light/20% */
  box-shadow: 0px 0.5px 2px rgba(61, 75, 92, 0.12),
    0px 0px 1px rgba(61, 75, 92, 0.05);
  border-radius: 8px;
  margin-left: 8px;
}

.unit-detail,
.unit-images {
  position: relative;
  padding-top: 38px;
}

.unit-image {
  position: relative;
  background-position: center !important;
  /* Center the image */
  background-repeat: no-repeat !important;
  /* Do not repeat the image */
  background-size: cover !important;
  /* Resize the background image to cover the entire container */
  background: #f0f2f5;
  border-radius: 12px;
}

.unit-image-button {
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  padding: 12px;
  /* Text 16/Semibold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  color: #ffffff;
  bottom: 24px;
}

.unit-image .image-slider {
  left: 24px;
}

.unit-image .image-360 {
  left: 162px;
}

.nomor-lot {
  padding: 4px 12px;
  gap: 8px;
  /* Primary/Icon */
  background: #cf1701;
  border-radius: 6px;
  /* Text 14/Semibold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  /* Base/White */
  color: #ffffff;
  margin-right: 8px;
}
.grade-desktop {
  position: relative;
}
.grade-desktop .nomor-lot {
  width: fit-content;
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  top: -90px;
  right: 0;
  z-index: 0;
  line-height: 20px;
  padding: 12px;
  border: 1px solid white;
}
.grade-desktop .nomor-lot span {
  font-size: 32px;
}
@media (max-width: 575.98px) {
  .grade-desktop {
    /* display: none; */
  }
  .footer-label {
    align-items: start;
    position: relative;
  }
  .footer-label .metode {
    /* align-self: flex-end; */
  }
}
.nomor-lot.nipl {
  background: #ff6a39;
  padding: 4px 8px;
}
.nomor-lot.nipl .text {
  color: black;
  background: orange;
  border: 2px solid #ffb836;
  padding: 0 5px;
  border-radius: 5px;
}
.nomor-lot.nipl .text.yellow {
  background: yellow;
  border: 2px solid rgb(255, 255, 74);
}
.badge-pumper {
  color: #007bff;
  background-color: #fff;
  border-radius: 10rem;
  border: 1px solid #007bff;
  font-size: 12px;
  padding: 1px 10px;
}
.unit-name {
  /* Headline 24/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  /* Base/Primary */
  color: #092540;
}

.alert-nipl {
  background: #ffebeb;
  color: #ff4d4d;
  padding: 8px 16px;
  font-size: 14px;
  text-align: center;
  margin-top: -20px;
  width: 112%;
  left: -6%;
  position: relative;
  margin-bottom: 25px;
}

.unit-counter {
  /* Text 14/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Secondary */
  color: #687083;
}

.unit-counter span {
  margin-right: 10px;
}

.unit-counter span:first-child {
  border-right: 1px solid #e4e7eb;
  padding-right: 10px;
}

.unit-title {
  /* Text 14/Semibold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Secondary */
  /* color: #687083; */
  color: #646566;
  display: block;
  margin-top: 1.25rem;
  /* margin-bottom: 0.5rem; */
}
.unit-price {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 32px;
  /* identical to box height, or 133% */

  /* Base/Primary */
  color: #092540;
}
.tax-detail {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Secondary */
  /* color: #687083; */
  color: #646566;
  display: block;
  margin-top: 4px;
  display: flex;
  align-items: center;
  position: relative;
}
.tax-detail svg {
  margin-left: 4px;
}
/* .tooltip {
  left: -25%;
}
#button-tooltip-tax {
  position: absolute;
} */
.tooltip-hover {
  padding: 8px;
  border-radius: 5px;
  background: #1e1e1f;
  color: white;
  position: absolute;
  width: 210px;
  left: 110px;
}
.tooltip-hover::before {
  background: #1e1e1f;
  padding: 6px;
  left: -5px;
  top: 11px;
  transform: rotate(45deg);
  content: "";
  border-radius: 3px;
  position: absolute;
}
.unit-note {
  display: flex;
  flex-direction: row;
  margin-top: 1.25rem;
}
.unit-note .left {
  width: fit-content;
  border-right: 1px solid #dcdde0;
  padding: 8px 0;
  padding-right: 20px;
}
.unit-note .right {
  width: fit-content;
  display: flex;
  padding: 8px 16px;
  margin-left: 20px;
  background: #fff0eb;
  border-radius: 4px;
}
.unit-time-bid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border: 1px solid #bebfc2;
  border-radius: 12px;
  position: relative;
}
.unit-time-bid .left {
  margin-right: 10px;
}
.unit-time-bid .left > * {
  margin: 0;
}
.unit-time-bid .left .d-flex > * {
  margin: 0 3px;
}
.unit-time-bid h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}
.unit-time-bid h4.offer {
  color: #c2000d;
  font-weight: bold;
}
.mulai-tawar {
  background: #4991f2;
  border-radius: 4px;
  color: #ffffff;
  padding: 4px 8px;
  font-size: 12px;
  width: max-content;
  left: -19px;
  top: -34px;
  position: absolute;
}
.mulai-tawar::after {
  content: "";
  background: #4991f2;
  transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
  position: absolute;
  width: 8px;
  height: 8px;
  left: calc(50% - 8px / 2 - 1.66px);
  bottom: -4px;
}

.unit-time-bid .right {
  position: relative;
}
.unit-time-bid .right button {
  border-radius: 8px;
  padding: 8px 20px;
  border: none;
}
.unit-time-bid .right .submit {
  background: #c2000d;
  color: white;
}

.unit-time-bid .right .cancel {
  background: #ffebec;
  color: #c2000d;
}
.unit-time-bid .right .change {
  background: white;
  color: black;
  border: 1px solid #bebfc2;
}
.unit-bid-price {
  /* Text 18/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  /* Base/Primary */
  color: #092540;
}
.unit-bid-price.nipl {
  /* padding-left: 25px; */
}
.unit-highlight > span {
  padding: 2px 10px;
  margin-right: 8px;

  /* Base/Light */
  /* background: #F0F2F5; */
  background: #e6e8eb;
  border-radius: 6px;

  /* Text 14/Semibold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Secondary */
  /* color: #687083; */
  color: #646566;
}
.unit-terjual {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  /* line-height: 29px */
  color: #1E1E1F;
}
.unit-terjual-container {
  background: #00000033;
  border-radius: 8px;
  height: 60px;
  width: '100%';

}

.unit-lelang-info {
  margin-top: 20px;
  padding: 16px;
  /* Base/Surface */

  /* background: #F9FAFB; */
  background: #f5f6f7;
  border-radius: 12px;
  margin-bottom: 20px;
}

.lelang-title {
  /* Headline 20/Bold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */

  /* Base/Primary */
  color: #092540;
  text-transform: uppercase;
}

.lelang-title > a {
  text-decoration: none;
  text-transform: capitalize;
  text-align: right;
  float: right;
}

.lelang-title > a > span.link {
  /* Text 14/Semibold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Primary */
  color: #092540;
}

.lelang-date {
  /* Text 16/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Primary/Icon */
  color: #cf1701;
  margin-bottom: 8px;
}

.lelang-time {
  display: table;
  /* Text 14/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Primary */
  color: #092540;
  margin-bottom: 8px;
}

.lelang-location {
  display: table;
  /* Text 14/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Primary */
  color: #092540;
  margin-bottom: 8px;
}

.lelang-time > span {
  display: table-cell;
  vertical-align: middle;
  padding-left: 10px;
}

.lelang-location > span {
  display: table-cell;
  vertical-align: middle;
  padding-left: 10px;
}

.hotline {
  /* Text 14/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Secondary */
  color: #687083;
}

.hotline span {
  margin-right: 12px;
}

.hotline a {
  text-decoration: none;
  /* Text 14/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Primary/Icon */
  color: #cf1701;
}

.informasi-unit {
  margin-top: 60px;
  padding: 20px;
  /* Base/White */

  background: #ffffff;
  /* Light/60% */

  box-shadow: 0px 4px 8px rgba(61, 75, 92, 0.12),
    0px 0px 1px rgba(61, 75, 92, 0.05);
  border-radius: 12px;
}

.informasi-unit h3 {
  /* Headline 24/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  /* Base/Primary */

  color: #092540;
  margin-bottom: 16px;
}

.informasi-unit .info {
  border-top: 1px solid #d1d5dc;
  clear: both;
  float: left;
  padding-bottom: 8px;
  padding-top: 8px;
}

.informasi-unit .info > .title-info {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Base/Secondary */
  color: #687083;
  float: left;
}

.informasi-unit .info > .value {
  /* Text 16/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: right;

  /* Base/Primary */
  color: #092540;
  float: right;
}

.inspeksi-kendaraan {
  margin-top: 60px;
  /* Base/White */

  background: #ffffff;
  /* Light/60% */

  box-shadow: 0px 4px 8px rgba(61, 75, 92, 0.12),
    0px 0px 1px rgba(61, 75, 92, 0.05);
  border-radius: 12px;
  padding: 20px;
}

.inspeksi-kendaraan .info {
  /* Base/Inline */

  border: 1px solid #d1d5dc;
  border-radius: 8px;
  padding: 12px;
  clear: both;
  float: left;
  margin-bottom: 10px;
}

.inspeksi-kendaraan .info div.description {
  clear: both;
  white-space: pre-wrap;
  overflow-x: auto;
}

.inspeksi-kendaraan h3 {
  /* Headline 24/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  /* Base/Primary */

  color: #092540;
  margin-bottom: 16px;
}

.inspeksi-kendaraan .info > .title-info {
  float: left;
  text-align: left;
  /* Text 18/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  /* Base/Primary */
  color: #092540;
}

.inspeksi-kendaraan .info > .value {
  float: right;
  text-align: right;
  /* Text 18/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  /* Base/Primary */
  color: #092540;
}

.inspeksi-kendaraan .info > .minimal-value {
  /* Text 18/Regular */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  /* Base/Secondary */

  color: #687083;
  float: right;
  text-align: right;
}

.produk-terkait {
  margin-top: 60px;
  margin-bottom: 60px;
}

.produk-terkait h3 {
  /* Headline 24/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  /* Base/Primary */

  color: #092540;
  margin-bottom: 16px;
}

.com-modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.com-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.com-modal-dialog {
  position: relative;
  width: auto;
  margin: 10rem;
  pointer-events: none;
}

.com-modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 12px;
  outline: 0;
  min-width: 450px;
  height: auto;

  /* Base/White */

  background: #ffffff;
  /* Light/40% */

  box-shadow: 0px 2px 4px rgba(61, 75, 92, 0.12),
    0px 0px 1px rgba(61, 75, 92, 0.05);
  border-radius: 12px;

  margin: 1.75rem auto;
}

.com-modal div.form-group,
.com-modal-content div.form-group {
  margin-bottom: 20px;
}

.com-modal div.header,
.com-modal-content div.header {
  margin-bottom: 20px;
}

.com-modal .title,
.com-modal-content .title {
  /* Text 18/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  /* Base/Primary */
  color: #092540;
  margin-bottom: 20px;
}

.com-modal .form-label,
.com-modal-content .form-label {
  /* Text 14/Semibold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Primary */
  color: #092540;
}

.com-modal .input-group-text {
  /* Text 14/Regular */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Secondary */
  color: #687083;
}

.com-modal .form-control,
.com-modal-content .form-control {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.com-modal .form-text {
  /* Text 12/Regular */
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  /* Base/Secondary */
  color: #687083;
}

.com-modal a {
  text-decoration: none;
  font-weight: 600;
}

.com-modal .group,
.group {
  display: flex;
  gap: 0.5rem;
}

.com-modal .group button {
  flex: 1 !important;
  box-shadow: 0px 0.5px 2px rgba(61, 75, 92, 0.12),
    0px 0px 1px rgba(61, 75, 92, 0.05);
  border-radius: 8px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;
}

.com-modal .group button.cancel {
  /* Base/White */
  background: #ffffff;
  /* Base/Inline */

  border: 1px solid #d1d5dc;
  /* Light/20% */
}

.com-modal .com-modal-content .com-modal-header {
  position: relative;
}

.text-line {
  position: relative;

  z-index: 1;
  overflow: hidden;
  /* Caption 12/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */
  text-align: center !important;

  /* Base/Secondary */
  color: #5c5c5c;
  margin-left: 40%;
}

.text-line:before,
.text-line:after {
  position: absolute;
  top: 51%;
  overflow: hidden;
  width: 50%;
  height: 1px;
  content: "\a0";
  background-color: #adadad;
}

.text-line:before {
  margin-left: -50%;
  text-align: right;
}

.form-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.3rem;
  outline: 0;
  z-index: 3000;
  /* width: 363px; */
  height: auto;

  /* Base/White */

  background: #ffffff;
  /* Light/40% */

  margin: auto;
}

.form-modal span.text {
  /* Text 14/Medium */

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Secondary */

  color: #5c5c5c;
}

.form-modal div.form-group {
  margin-bottom: 12px;
}

.form-modal .form-label,
.form-label {
  /* Text 14/Semibold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Primary */
  color: #092540;
}

.form-modal .form-control {
  /* Text 14/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Primary */
  color: #1a1a1a;
}

.form-modal .form-check label {
  /* Text 14/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Primary */
  color: #1a1a1a;
}

.form-modal .input-group-text {
  /* Text 14/Regular */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Secondary */
  color: #687083;
}

.form-modal .form-text {
  /* Text 12/Regular */
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  /* Base/Secondary */
  color: #687083;
}

.form-modal a {
  text-decoration: none;
  font-weight: 600;
  /* Text 14/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Primary/Icon */

  color: #c20102;
}

.form-modal .group {
  display: flex;
  gap: 8px;
}

.form-modal .group button,
.form-modal .button-login {
  flex: 1 !important;
  box-shadow: 0px 0.5px 2px rgba(61, 75, 92, 0.12),
    0px 0px 1px rgba(61, 75, 92, 0.05);
  border-radius: 8px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;
}

.form-modal .group button.cancel {
  /* Base/White */
  background: #ffffff;
  /* Base/Inline */

  border: 1px solid #d1d5dc;
  /* Light/20% */
}

.share {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center !important;
}

.social-media-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  box-shadow: 0px 0.5px 2px rgba(61, 75, 92, 0.12),
    0px 0px 1px rgba(61, 75, 92, 0.05);
  border-radius: 8px;
  border: none;
}

.social-media-button.facebook {
  background: #3c5997;
}

.social-media-button.twitter {
  background: #1da1f1;
}

.social-media-button.whatsapp {
  background: #24d366;
}

.copy-link {
  /* Text 14/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Primary */
  color: #1a1a1a;
  margin-top: 8px;
}

.modal-header {
  /* Text 18/Bold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  /* Base/Primary */
  color: #1a1a1a;
}
.noheader .modal-header {
  padding: 0;
}

.image-circle {
  width: 100px;
  height: 100px;
  background-color: red;
  background-image: linear-gradient(
    to right,
    rgba(207, 23, 1, 1),
    rgba(157, 17, 1, 1)
  );
  border-radius: 50px;
}

.background-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #1a1a1a;
  mix-blend-mode: multiply;
  opacity: 0.5;
}

.d-flex {
  display: flex;
}

.flex-center {
  justify-content: center;
  align-items: center !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.titip-lelang {
  position: relative;
  padding: 137px;
}

.titip-lelang h2 {
  /* Headline 32/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  text-align: center;

  /* Base/White */

  color: #ffffff;

  margin-bottom: 48px;
}

.titip-lelang a,
.titip-lelang .readmore {
  /* Text 16/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Base/White */

  color: #ffffff;
  text-decoration: none;
}

.bullet-titip-lelang {
  flex-direction: row !important;
  gap: 1.5rem;
}

.pack {
  /* Headline 20/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Base/White */
  color: #ffffff;
  min-width: 191px;
  margin-bottom: 1rem;
}

.pack div.text-bullet {
  position: relative;
  margin-top: 10px !important;
}

.pack .image-circle {
  margin: auto !important;
}

.cover-background {
  background-position: center !important;
  /* Center the image */
  background-repeat: no-repeat !important;
  /* Do not repeat the image */
  background-size: cover !important;
  /* Resize the background image to cover the entire container */
}

._3bkri {
  background-color: transparent !important;
}

._20cg_ {
  border-radius: 6px !important;
}

.tab-content-caready {
  padding: 20px;
}

.tab-content-caready .title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  /* Base/Primary */
  color: #1e1e1f;
  margin-bottom: 20px;
}

.tab-content-caready .sub-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  /* Base/Primary */
  color: #1e1e1f;
}

.tab-content-caready .nav-tabs .nav-link {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  color: #1e1e1f;
  border: none !important;
}

.tab-content-caready .nav-tabs .nav-link.active,
.tab-content-caready .nav-tabs .nav-item.show .nav-link {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  color: #c20102;
  border-bottom: 3px solid #c20102 !important;
}

.tab-content-caready .form-check-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Primary */
  color: #1e1e1f;
}

.required:after {
  content: " *";
  color: red;
}

.form-caready .form-group {
  margin-bottom: 12px !important;
}

.form-caready .form-control {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Primary */
  color: #1e1e1f;
}

.form-caready button {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;
  /* Base/White */

  color: #ffffff;
  margin-top: 8px;
}

.form-caready .form-check label {
  /* Text 14/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Primary */
  color: #1e1e1f;
}

ul.notification li {
  display: flex;
  border-bottom: 1px solid #dcdde0;
  padding: 10px;
}

ul.notification li h4 {
  /* Text 16/Semibold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Base/Primary */
  color: #1e1e1f;
  margin-bottom: 0px !important;
}

ul.notification li .desc-notif {
  /* Text 14/Regular */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Secondary */
  color: #646566;
}

ul.notification li .date-notif {
  /* Caption 12/Regular */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  /* Base/Secondary */
  color: #646566;
}

.card-transaksi {
  border: 1px solid #dcdde0;
  border-radius: 12px;
  min-height: 50px;
  margin-bottom: 10px;
}

.card-transaksi .header {
  /* display: flex; */
  background: #f5f6f7;
  padding: 10px;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
}

.card-transaksi .header .title,
.card-transaksi .title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  /* Base/Secondary */
  color: #646566;
}

.card-transaksi .header .value {
  /* Text 14/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Secondary */
  color: #646566;
}

.card-transaksi .body {
  padding: 10px;
}

.card-transaksi .unit-name {
  /* Text 14/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  /* Base/Secondary */
  color: #646566;
  /* height: 40px; */
}

.card-transaksi .harga {
  /* Text 14/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
}

.card-transaksi .harga.final {
  /* Base/Primary */
  color: #1e1e1f;
}

.card-transaksi .harga.tagihan {
  /* Base/Primary */
  color: #c20102;
}

.card-transaksi .tanggal {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Primary */
  color: #1e1e1f;
}

.card-transaksi .button {
  position: relative;
  float: right !important;
}

.card-transaksi .button a {
  display: block;
  text-decoration: none;
}

.card-transaksi .button a.btn-bayar,
.card-transaksi .button .btn-bayar {
  /* Text 14/Semibold */
  padding: 8px 20px;
  gap: 8px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  /* Base/White */
  color: #ffffff;

  /* Primary/Icon */
  background: #c20102;
  /* Light/20% */

  box-shadow: 0px 0.5px 2px rgba(61, 75, 92, 0.12),
    0px 0px 1px rgba(61, 75, 92, 0.05);
  border-radius: 8px;
  margin-bottom: 8px;
}

.card-transaksi .button a.btn-detail,
.card-transaksi .button .btn-detail {
  padding: 8px 20px;
  gap: 8px;
  /* Text 14/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  /* Base/Primary */

  color: #1e1e1f;

  /* Base/White */

  background: #ffffff;
  /* Base/Inline */

  border: 1px solid #bebfc2;
  border-radius: 8px;
}

.card-transaksi .auction {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  /* Base/Secondary */

  color: #646566;
  padding-top: 5px;
  padding-right: 5px;
}

.card-transaksi .info-unit {
  border-right: 1px solid #bebfc2;
  /* min-height:114px; */
}

.unit-image {
  position: relative;
  background-position: center !important;
  /* Center the image */
  background-repeat: no-repeat !important;
  /* Do not repeat the image */
  background-size: cover !important;
  /* Resize the background image to cover the entire container */
  background: #f0f2f5;
  border-radius: 12px;
  width: 127px;
  height: 95px;
  margin-right: 10px;
}

.unit-slider .carousel .control-dots {
  text-align: right;
  padding-left: 24px;
  padding-right: 24px;
}

.unit-slider .carousel-slider {
  border-radius: 12px;
}

.unit-slider .carousel .control-dots .dot,
.slick-dots li button {
  width: 16px !important;
  height: 6px !important;

  /* Base/White */
  background: #ffffff !important;
  opacity: 0.4;
  border-radius: 3px;
  margin: 0 3px;
  padding: 0 !important;
}

.slick-dots li.slick-active button {
  opacity: 1;
}

.slick-list {
  border-radius: 12px;
}

.auction-list .slick-list {
  margin: 0 23px;
}

.slick-list .card-unit {
  margin-right: 10px !important;
}

.slick-list .card-unit:first-child {
  margin-left: 10px !important;
}

.slick-slide img {
  width: 100%;
  border-radius: 12px;
}

.slick-dots {
  bottom: 24px !important;
  text-align: right !important;
  padding-right: 24px !important;
}

.slick-slider.dots-left .slick-dots {
  bottom: 24px !important;
  text-align: left !important;
  padding-left: 24px !important;
}

.slick-dots li {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.slider-arrow {
  position: absolute;
  cursor: pointer;
  top: calc(50% - 48px / 2 - 0.5px);
  background: rgba(0, 0, 0, 0.4);
  padding: 12px;
  z-index: 1;
}

.slider-arrow.previous {
  left: 0;
}

.slider-arrow.next {
  right: 0;
}

.home-slider .slick-list {
  border-radius: 0px !important;
}

.home-slider-item {
  border-radius: 0px !important;
  height: 547px;
}

.home-slider-arrow {
  position: absolute;
  cursor: pointer;
  top: calc(50% - 48px / 2 - 0.5px);
  padding: 10px;
  z-index: 1;
  /* Base/White */

  background: #ffffff;
  /* Base/Inline */

  border: 1px solid #bebfc2;
  border-radius: 8px;
}

.home-slider-arrow.previous {
  left: 32px;
}

.home-slider-arrow.next {
  right: 32px;
}

.slick-dots li button:before {
  content: none !important;
}

.badge-status {
  padding: 1px 8px;
  /* Caption 12/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */
  border-radius: 6px;
}

.badge-status.primary {
  color: #0d6efd;
  background: #fef8e7;
}

.badge-status.warning {
  color: #a84d20;
  background: #fef8e7;
}

.badge-status.success {
  color: #1d8242;
  background: #eaf8ef;
}

.badge-status.danger {
  color: #b33636;
  background: #ffeded;
}

.badge-status.secondary {
  color: #3c3c3d;
  background: #e6e8eb;
}

.badge-status.info {
  color: #295ecc;
  background: #f0f8fe;
}

.badge-lot > .metode.blue {
  float: right;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  padding: 4px 12px;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid blue;
  color: blue;
  height: auto;
  text-align: center;
}

/* raafi was here */
.snk > div {
  margin-bottom: 2rem;
}

.adOne {
  background-image: url("../images/homeAd/one.png") !important;
}

.adTwo {
  background-image: url("../images/homeAd/three.webp");
}

.adThree {
  background-image: url("../images/homeAd/two.webp");
}

.banner {
  background-image: url("../images/banner.png");
  height: 547px;
}

.skew-label::after {
  position: absolute;
  top: 0px;
  right: -1em;
  content: "";
  background: #cf1701;
  width: 1.7em;
  height: 100%;
  transform: skewX(-30deg);
  border-bottom-right-radius: 0.7em;
}

.mt-15 {
  margin-top: 30px;
}

.box-border {
  position: relative;
  /* Base/White */
  background: #ffffff;
  /* Light/40% */

  box-shadow: 0px 2px 4px rgba(61, 75, 92, 0.12),
    0px 0px 1px rgba(61, 75, 92, 0.05);
  border-radius: 12px;
  padding: 20px;
}

.box-border .title {
  /* Text 18/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  /* Base/Primary */
  color: #092540;
  margin-bottom: 20px;
}

.unit-slider {
  position: relative;
}

.blog-content img {
  width: 100%;
}

.grade-unit-auction {
  position: absolute;
  bottom: 24px !important;
  right: 24px !important;
  text-align: center;
  padding: 8px 12px;
  /* Primary/Icon */

  background: #c20102;
  /* Base/White */

  border: 1px solid #ffffff;
  border-radius: 8px;
  color: #ffffff;
  /* Caption 12/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  text-align: center;
}

.grade-auction {
  /* Headline 32/Bold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  text-align: center;
}

.unit-name-auction {
  /* Headline 28/Regular */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  /* identical to box height, or 129% */

  /* Base/Primary */
  color: #1e1e1f;
  margin-top: 20px;
}

.informasi-unit-auction {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  flex: 0 0 31.533333%;

  /* Base/White */

  background: #ffffff;
  /* Base/Outline */

  border: 1px solid #dcdde0;
  border-radius: 2px;
}

.informasi-unit-auction .title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  /* Base/Primary */
  color: #1e1e1f;
}

.informasi-unit-auction .value {
  /* Text 14/Bold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Primary */
  color: #1e1e1f;
  text-transform: uppercase;
}

.count-customer {
  /* Headline 20/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */

  /* Base/Secondary */
  color: #646566;
}

.bidder-list {
  background: #ffffff;
  padding-bottom: 18px;
  box-shadow: 0px 2px 4px rgba(61, 75, 92, 0.12),
    0px 0px 1px rgba(61, 75, 92, 0.05);
  border-radius: 12px;
}

.bidder-list .header {
  display: flex;
  background: #ffffff;
  padding-top: 28px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 28px;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
}

.bidder-list .title,
.box-border .title-header {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */

  /* Base/Primary */
  color: #1e1e1f;
  flex: 1;
  margin: 10px0;
}
.box-border .coupon-header {
  border-radius: 8px;
  padding: 6px 16px;
  text-align: right;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid #dcdde0;
  color: #646566;
  text-align: center;
}
.box-border .coupon-header svg {
  top: 4px;
  margin: 0 5px;
  position: relative;
}
.box-border .coupon-header span {
  color: red;
}
.coupon-footer {
  padding: 6px 0;
  text-align: right;
  font-size: 14px;
  font-weight: normal;
  color: #646566;
  text-align: right;
  min-width: 180px;
  align-items: center;
}
.coupon-footer svg {
  /* top: 4px; */
  margin-right: 5px;
  position: relative;
}
.coupon-footer span {
  color: red;
}
.coupon-footer p {
  padding-right: 10px;
  border-right: 1px solid #dcdde0;
}
.bidder-list .timer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 24px;
  gap: 6px;
  border: 1px solid #c20102;
  border-radius: 24px;

  /* Headline 20/Bold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */

  text-align: right;
  /* Primary/Icon */
  color: #c20102;
}

.highest-bid {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 20px;

  /* Primary/Surface */
  background: #f9e6e6;
}

.highest-bid .bid-value {
  /* Headline 28/Bold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  /* identical to box height, or 129% */

  /* Primary/Icon */
  color: #c20102;
}

.bidder-data {
  max-height: 250px !important;
  overflow-y: auto;
}

.bidder {
  /* Text 16/Regular */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: right;
  /* Base/Primary */

  color: #1e1e1f;
}

.bidder-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 20px;
  border-bottom: 1px solid #dcdde0;
}

.bidder-item.active {
  background-color: #fff0eb;
}

.bidder-item .bid-value {
  /* Text 16/Bold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Base/Primary */
  color: #1e1e1f;
}

.plus-minus {
  background: #ffffff;
  /* Primary/Inline */

  border: 1px solid #bebfc2;
}

.input-group-text {
  background: #f5f6f7;
  color: #646566;
}

.bid-on {
  padding: 12px 28px;
  width: 160px;
  height: 48px;
  /* Text 16/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Base/White */
  color: #ffffff;
}

.bid-off {
  padding: 12px 28px;
  width: 160px;
  height: 48px;
  /* width: 321px;
  height: 48px; */
  /* Text 16/Semibold */

  /* Text 16/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Base/Inline */
  color: #bebfc2;
  background: #e6e8eb;
  border: 1px solid #e6e8eb;
}

.form-switch.left-label {
  display: flex;
  flex-direction: row-reverse;
  float: left !important;
  padding-left: 0px;
  align-self: center !important;
}

.form-switch.left-label label {
  margin-right: 50px !important;
  /* Text 14/Regular */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Primary */
  color: #1e1e1f;
}

.box-lelang {
  position: relative;
  /* Base/White */
  background-color: #ffffff;
  /* Light/40% */

  box-shadow: 0px 2px 4px rgba(61, 75, 92, 0.12),
    0px 0px 1px rgba(61, 75, 92, 0.05);
  border-radius: 12px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  /* Do not repeat the image */
}

.tes {
  align-items: flex-start;
  justify-content: space-between !important;
}

.page-header {
  min-height: 395px;
  /* Headline 48/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  /* or 133% */

  text-align: center;

  color: #ffffff;
}

.pagination-container {
  display: flex;
  list-style-type: none;
  justify-content: center;
  align-items: center;

}
.pagination-container-unit {
  display: flex;
  justify-content: center;
  align-items: center;


}
.rowPagination{
  padding: 0px;

}

.paginationNone {
  display: none;
}

.pagination-item {
  /* padding: 8px 20px; */
  text-align: center;
  /* margin: auto 4px; */
  /* cursor: pointer; */
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.01071em;
  border-radius: 8px;
  border: 1px solid #bebfc2;
  border-radius: 8px;
  /* Text 14/Semibold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;
  /* Base/Primary */
  color: #1e1e1f;
}

.pagination-item a {
  min-width: 30px;
  min-height: 30px;
  line-height: 30px;
}

.pagination-item.active {
  background: #c20102;
  border: 1px solid #c20102;
  /* Light/20% */
  box-shadow: 0px 0.5px 2px rgba(61, 75, 92, 0.12),
    0px 0px 1px rgba(61, 75, 92, 0.05);
  color: #ffffff;
}

.paginationUnit-item.previous {
  /* padding: 8px 20px; */

  text-align: center;
  /* margin: auto 4px; */
  /* cursor: pointer; */
  display: flex;
  /* box-sizing: border-box; */
  align-items: center;
  justify-content: center;
  /* letter-spacing: 0.01071em; */
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  /* border: 1px solid #bebfc2; */
  background-color: #c20102;
  /* Text 14/Semibold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  /* margin-right: -1px; */
  margin-left: -32px;
  text-align: center;
  /* Base/Primary */
  /* color: #000000; */
  border-color:#c20102 ;
}
.paginationUnit-item.next {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  /* margin-left: 0px; */
  /* margin-right: 10px; */
  /* padding: 8px 20px; */
  text-align: center;
  /* margin: auto 4px; */
  /* cursor: pointer; */
  display: flex;
  /* box-sizing: border-box; */
  align-items: center;
  justify-content: center;
  /* letter-spacing: 0.01071em; */

  /* border: 1px solid #bebfc2; */

  background-color: #c20102;
  /* Text 14/Semibold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;
  /* Base/Primary */
  /* color: #000000; */
}

.paginationUnit-item a {
  min-width: 30px;
  min-height: 30px;
  line-height: 30px;
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.pagination-item a {
  min-width: 30px;
  min-height: 30px;
  line-height: 30px;
}

.pagination-item.active {
  background: #c20102;
  border: 1px solid #c20102;
  /* Light/20% */
  box-shadow: 0px 0.5px 2px rgba(61, 75, 92, 0.12),
    0px 0px 1px rgba(61, 75, 92, 0.05);
  color: #ffffff;
}


.pagination-item.disabled {
  border: 1px solid #dcdde0;
}
.paginationUnit-item.disabled {
  border: 1px solid #c20102;
}
.paginationUnitDisabled {

}

.pagination-item.arrow.active {
  background: #f9e6e6;
  border: 1px solid #f9e6e6;
}

.article {
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px;
}

.article .date {
  /* Text 16/Semibold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Base/Secondary */
  color: #646566;
  margin-bottom: 10px;
}

.article .title {
  /* Headline 40/Semibold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  /* or 120% */
  text-align: center;

  /* Base/Primary */
  color: #1e1e1f;
  margin-top: 10px;
  margin-bottom: 10px;
}

.article .tag {
  padding: 4px 12px;

  /* Base/Light */

  background: #e6e8eb;
  border-radius: 6px;
  /* Text 14/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Label */
  color: #3c3c3d;
}

.article .image {
  margin-top: 30px;
  margin-bottom: 30px;
  /* height: 600px; */
  background-color: #dcdde0;
}

.article .desc {
  padding-top: 30px;
  padding-bottom: 30px;
  /* Text 18/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* or 156% */
  text-align: center;

  /* Base/Secondary */
  color: #646566;
}

.subscribe .text {
  /* Headline 32/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  /* Base/Primary */
  color: #1e1e1f;
}

.subscribe .subscribe-input button {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;
  /* Base/White */
  color: #ffffff;
  background: #c20102;
  /* Light/20% */

  box-shadow: 0px 0.5px 2px rgba(61, 75, 92, 0.12),
    0px 0px 1px rgba(61, 75, 92, 0.05);
  border-radius: 0px 8px 8px 0px;
}

.subscribe .subscribe-input .form-control {
  /* Text 14/Regular */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Icon */
  color: #8c8d8f;
  /* Base/Inline */

  border: 1px solid #bebfc2;
  border-radius: 8px 0px 0px 8px;
}

.title {
  /* Headline 32/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  /* Base/Primary */
  color: #1e1e1f;
  margin-bottom: 20px;
}

.desc {
  /* Text 16/Regular */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Base/Secondary */
  color: #646566;
}

.visi-misi {
  background: #f5f6f7;
  padding-top: 50px;
  padding-bottom: 50px;
}

.locations {
  padding-top: 50px;
  padding-bottom: 50px;
  background-position: center !important;
  /* Center the image */
  background-repeat: no-repeat !important;
  /* Do not repeat the image */
}

.locations .title {
  /* Headline 20/Semibold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */

  /* Base/Secondary */
  color: #646566;
  text-align: center;
}

.locations .location {
  /* Headline 40/Bold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  /* identical to box height, or 120% */

  /* Primary/Icon */
  color: #c20102;
  text-align: center;
}

.services {
  margin-bottom: 50px;
}

.services .bullet {
  width: 50px;
  height: 50px;

  /* Primary/Surface */
  background: #f9e6e6;
  border-radius: 50px;
  margin-bottom: 16px;
}

.services .title {
  /* Text 18/Bold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  /* Base/Label */
  color: #3c3c3d;
  margin-bottom: 8px !important;
}

.services .desc {
  /* Text 16/Regular */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Base/Secondary */
  color: #646566;
}

.mt-100 {
  margin-top: 100px;
}

.mt-150 {
  margin-top: 150px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-1 {
  margin-left: 0.5rem;
}

.center-horizontal-vertical {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.center-vertical {
  display: flex;
  align-items: center;
  justify-content: left;
}

.contact-us .title {
  /* Headline 40/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  /* identical to box height, or 120% */

  /* Base/Primary */
  color: #1e1e1f;
}

.contact-us .section {
  /* Text 14/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Secondary */
  color: #646566;
  margin-bottom: 20px;
}

.contact-us .section .text-primary {
  /* Text 14/Semibold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Primary/Icon */
  color: #c20102;
}

.contact-us .section .title {
  /* Text 18/Semibold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  /* Base/Primary */
  color: #1e1e1f;
}

.contact-us .section .icon {
  margin-right: 20px;
}

.btn-pink {
  position: relative;
  text-decoration: none;
  align-items: center;
  padding: 10px 24px;
  gap: 8px;

  /* Primary/Surface */

  background: #f9e6e6;
  border-radius: 8px;
  /* Text 14/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  /* Primary/Icon */

  color: #c20102 !important;
  border: none !important;
}

.btn-pink:hover {
  position: relative;
  text-decoration: none;
  align-items: center;
  padding: 10px 24px;
  gap: 8px;

  /* Primary/Surface */

  background: #f9e6e6;
  border-radius: 8px;
  /* Text 14/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  /* Primary/Icon */

  color: #c20102 !important;
  border: none !important;
}

.titip-lelang-title {
  /* Headline 48/Bold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 48px !important;
  line-height: 64px !important;
  /* or 133% */

  color: #ffffff;
}

.tahapan-lelang {
  background: #f5f6f7;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.tahapan-lelang .title {
  /* Headline 32/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  /* identical to box height, or 125% */
  text-align: center;

  /* Base/Label */
  color: #3c3c3d;
}

.tahapan-lelang .cards {
  position: relative;
  /* Base/White */
  background: #ffffff;
  /* Light/40% */

  box-shadow: 0px 2px 4px rgba(61, 75, 92, 0.12),
    0px 0px 1px rgba(61, 75, 92, 0.05);
  border-radius: 12px;
  margin-bottom: 20px;
}

.tahapan-lelang .cards .number {
  position: relative;
  width: 50px;
  padding-left: 16px;
  padding-top: 8px !important;
  padding-bottom: 8px;
  padding-right: 16px;
  /* Primary/Icon */
  background: #c20102;

  /* Headline 32/Bold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  /* Base/White */
  color: #ffffff;

  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.tahapan-lelang .cards .title {
  /* Headline 20/Bold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */

  /* Base/Label */
  color: #3c3c3d;
  margin-bottom: 8px !important;
  text-align: left;
}

.tahapan-lelang .cards .desc {
  /* Text 14/Regular */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  /* Base/Secondary */
  color: #646566;
}

.kelebihan {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.kelebihan-left h3.title {
  /* Headline 32/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  /* Base/Primary */
  color: #1e1e1f;
  margin-bottom: 30px !important;
}

.kelebihan-left .bullet {
  width: 40px !important;
  height: 40px !important;
  /* Primary/Surface */
  background: #d20011;
  border-radius: 40px;
}

.kelebihan-left .title {
  /* Text 18/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  /* Base/Label */
  color: #3c3c3d;
  margin-bottom: 8px !important;
}

.kelebihan-left .desc {
  /* Text 16/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Base/Secondary */
  color: #646566;
}

.kelebihan-right .image {
  width: 510px;
  height: 358px;
  overflow: hidden;

  /* Base/Light */

  background: #e6e8eb;
  border-radius: 12px;
}

.counter {
  /* Red-Gradient */
  background: linear-gradient(99.09deg, #cf1701 0%, #9d1101 100%);
  padding-top: 50px;
  padding-bottom: 50px;
}

.counter .number {
  /* Headline 48/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  /* identical to box height, or 133% */

  color: #ffffff;
}

.counter .desc {
  /* Headline 20/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */

  color: #ffffff;
}

.reviews {
  background: #f5f6f7;
}

.reviews .title {
  /* Headline 32/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  /* or 125% */
  text-align: center;

  /* Base/Label */
  color: #3c3c3d;
  margin-bottom: 8px !important;
}

.reviews .tagline {
  margin-bottom: 50px !important;
}

.reviews .cards-reviews {
  position: relative;
  /* Base/White */
  background: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.reviews .cards-reviews .image {
  width: 100%;
  min-height: 265px;
  /* Base/Light */

  background-color: #e6e8eb;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.reviews .cards-reviews .content {
  padding: 20px;
}

.reviews .cards-reviews .content .title {
  /* Headline 20/Bold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */

  /* Base/Label */
  color: #3c3c3d;
  text-align: left !important;
}

.reviews .cards-reviews .content .desc {
  /* Text 16/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Base/Secondary */
  color: #646566;
}

.reviews .cards-reviews .footer {
  padding: 20px;
}

.reviews .cards-reviews .footer a {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-decoration-line: underline;

  /* Primary/Icon */

  color: #c20102;
}

.slider-arrow.reviews {
  position: absolute;
  top: calc(50% - 48px / 2 - 0.5px);
  background: rgba(0, 0, 0, 0.4);
  padding: 8px;
  z-index: 1;
  /* Primary/Icon */

  background: #c20102;
  /* Light/20% */

  box-shadow: 0px 0.5px 2px rgba(61, 75, 92, 0.12),
    0px 0px 1px rgba(61, 75, 92, 0.05);
  border-radius: 8px;
}

.slider-arrow.previous.reviews {
  left: 0;
}

.slider-arrow.next.reviews {
  right: 0;
}

.navigation-sticky {
  display: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.navigation-sticky ul::-webkit-scrollbar {
  display: none;
}

.navigation-sticky ul {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  list-style: none;
  padding: 0px;
  overflow: auto;
  white-space: nowrap;
  margin: 0;
}

.navigation-sticky ul li {
  display: inline-block;
  padding: 10px 20px;
  gap: 10px;
  /* Text 18/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  /* Base/Secondary */

  color: #646566;
  text-decoration: none;
  cursor: pointer;
  text-transform: capitalize !important;
}

.navigation-sticky ul li a {
  display: block;
  color: #646566;
  text-decoration: none;
  text-transform: capitalize !important;
}

.navigation-sticky ul li.active {
  border-bottom: 2px solid #c20102;
  color: #c20102;
}

.navigation-sticky ul li.active a {
  color: #c20102;
}
.time-bid-header {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #c2000d;
  /* padding: 10px; */
  height: 64px;
  background: #ffebec;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.time-bid-header .timer {
  padding: 0 4px;
}
.time-bid-header .timer span {
  padding: 8px;
  width: 32px;
  height: 32px;
  background: #c2000d;
  border-radius: 4px;
  color: white;
  margin: 0 4px;
}
.is-sticky {
  display: block;
  position: sticky !important;
  top: 115px;
  left: 0;
  width: 100%;
  z-index: 2;
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
  /* border-top: 1px solid #CCC; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px -2px;
}

.grade-unit-detail {
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  gap: 2px;

  width: 60px;
  height: 82px;

  /* Primary/Icon */

  border: 1px solid #c20102;
  border-radius: 8px;
  /* Text 14/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  /* Primary/Icon */

  color: #c20102;
}

.grade-detail {
  /* Headline 28/Bold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  /* identical to box height, or 129% */

  text-align: center;

  /* Primary/Icon */

  color: #c20102;
}

.image-inspeksi {
  min-width: 120px;
  width: 120px;
  height: 90px;

  /* Base/Outline */

  background: #dcdde0;
  border-radius: 8px;
  float: left !important;
  margin-right: 0.5rem;
}

.inspeksi {
  margin-bottom: 25px;
}

.inspeksi .group {
  flex-direction: row;
  border: 1px;
  border-bottom: 1px solid #bebfc2;
  padding-top: 5px;
  padding-bottom: 5px;
}

.inspeksi .group:last-child {
  border-bottom: none !important;
}

.inspeksi .title-section {
  /* Headline 24/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  /* Base/Primary */
  color: #1e1e1f;
  margin-bottom: 20px;
}

.inspeksi .group .title-inspek {
  flex: 1;
  /* Text 14/Regular */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Primary */

  color: #1e1e1f;
}

.inspeksi .group .title-inspek:after {
  content: ":";
  color: #1e1e1f;
}

.inspeksi .group .value-inspek {
  flex: 1;
  /* Text 14/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px !important;
  /* identical to box height, or 143% */

  /* Base/Primary */
  color: #1e1e1f;
}

.image-kategori-inspek {
  width: 100%;
  min-height: 318px;
  background-position: center !important;
  /* Center the image */
  background-repeat: no-repeat !important;
  /* Do not repeat the image */
  background-size: cover !important;
  /* Resize the background image to cover the entire container */
}

.desc-kategori-inspek {
  padding: 20px;

  width: 100%;
  min-height: 318px;

  /* Base/Label */

  background: #3c3c3d;
}

.desc-kategori-inspek .title {
  /* Text 18/Bold */
  font-family: "Source Sans Pro" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 28px !important;
  /* identical to box height, or 156% */

  /* Base/White */

  color: #ffffff !important;
}

.desc-kategori-inspek .desc {
  /* Text 14/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  /* Base/White */

  color: #ffffff;
}

.subtitle {
  /* Text 16/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Base/Primary */
  color: #1e1e1f;
}

.result-inspek {
  /* justify-content: space-between; */
  border-bottom: 1px solid #bebfc2;
  padding-bottom: 10px;
  padding-top: 10px;
}

.result-inspek .images .image {
  margin-top: 5px;
  width: 120px;
  height: 90px;

  /* Base/Outline */

  background-color: #dcdde0;
  border-radius: 8px;
  background-position: center !important;
  /* Center the image */
  background-repeat: no-repeat !important;
}

.data-invoice .title {
  /* Text 14/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Secondary */
  color: #646566;
}

.data-invoice .value {
  /* Text 16/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Base/Secondary */
  color: #646566;
}

.no-underline {
  text-decoration: none;
}

.mt-20 {
  margin-top: 20px !important;
}

.fw-bold {
  font-weight: bold;
}

.summary-transaction {
  margin-top: 1rem;
}

.summary-transaction .title {
  /* Text 18/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  /* Base/Primary */
  color: #1e1e1f;
  margin-bottom: 20px;
}

.amount-item {
  padding: 10px;
  /* Base/Outline */

  border: 1px solid #dcdde0;
  border-radius: 8px;
}

.item-name {
  /* Text 14/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Secondary */
  color: #646566;
}

.item-amount {
  /* Text 14/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Primary */
  color: #092540;
}

.border-bottom-amount {
  margin-bottom: 10px;
  border-bottom: 1px solid #bebfc2;
  padding-bottom: 10px;
}

.summary-name {
  /* Text 14/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Primary */

  color: #092540;
}

.summary-amount {
  /* Headline 20/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */

  /* Base/Primary */
  color: #092540;
}

.summary-note {
  /* Text 14/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  text-align: center;

  /* Base/Secondary */
  color: #646566;
}

.subtitle-transaction {
  /* Text 16/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Base/Primary */

  color: #092540;
}

.amount-item .item-unit {
  /* Text 14/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Primary */

  color: #1e1e1f;
}

.nav-item-vertical {
  /* Text 16/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Base/Secondary */
  color: #646566;
  padding: 8px;
  gap: 10px;

  /* Primary/Surface */
  border-radius: 8px;
}

.nav-item-vertical.active {
  /* Text 16/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Primary/Icon */

  color: #c20102;

  background: #f9e6e6;
}

.title-procedure {
  /* Headline 24/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  /* Base/Primary */
  color: #1e1e1f;
}

.image-item-procedure {
  width: 100px;
  height: 100px;
  background-color: red;
  background-image: linear-gradient(
    to right,
    rgba(207, 23, 1, 1),
    rgba(157, 17, 1, 1)
  );
  border-radius: 50px;
}

.title-item-procedure {
  /* Text 16/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Base/Primary */
  color: #1e1e1f;
}

.desc-item-procedure {
  /* Text 14/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  /* Base/Secondary */

  color: #646566;
}

.flex-direction-column {
  display: flex !important;
  flex-direction: column !important;
}

.btn-upload {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  gap: 8px;

  /* Primary/Surface */

  background: #f9e6e6;
  border-radius: 8px;

  /* Text 14/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  /* Primary/Icon */

  color: #c20102 !important;
  border: none !important;
}

.btn-upload:hover,
.btn-upload:focus {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  gap: 8px;

  /* Primary/Surface */

  background: #f9e6e6;
  border-radius: 8px;

  /* Text 14/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  /* Primary/Icon */

  color: #c20102 !important;
  border: none !important;
}

.image-preview {
  width: 245px;
  min-height: 160px;

  /* Base/Surface */

  background-color: #f5f6f7 !important;
  /* Base/Outline */

  border: 1px dashed #dcdde0;
  border-radius: 8px;
  background-position: center !important;
  /* Center the image */
  background-repeat: no-repeat !important;
  /* Do not repeat the image */
  margin-right: 16px;
}

.image-preview img {
  position: relative;
  width: 100%;
}

.error-message {
  margin-top: 5px;
  color: red;
  font-size: 13px;
}

.text-primary {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Primary/Icon */
  color: #c20102;
}

.menu-dropdown {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #092540;
  padding: 8px 12px;
}

.menu-dropdown:hover {
  background-color: #e9ecef;
  border-radius: 8px;
}

.faq-title {
  background: #c20102;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}

.faq-tab:hover {
  color: #c20102;
  cursor: pointer;
}

.faq-tab {
  padding: 5px 20px 5px 20px;
  border-bottom: 2px solid transparent;
  font-weight: 600;
  white-space: nowrap;
}

.faq-tab-active {
  color: #c20102;
  border-bottom: 2px solid #c20102;
}

.la.la-arrow-down {
  transition: transform 200ms ease;
}

.is-open .la.la-arrow-down {
  transform: rotate(0.5turn);
}

.location-tab {
  margin-bottom: 10px;
  border: 2px solid transparent;
  padding: 20px;
  border-radius: 10px;
  display: flex;
}

.location-tab:hover,
.location-tab-active {
  border: 2px solid #c20102;
  cursor: pointer;
}

.with-background {
  background: #e6e8eb;
  background-position: center !important;
  /* Center the image */
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.inspeksi-slider {
  width: 100%;
  height: 420px !important;
  /* Base/Light */
  border-radius: 9.71098px;
}

.detail-unit-slider {
  width: 100%;
  height: 540px;
  /* Base/Light */
  border-radius: 12px;
}

.css-1okebmr-indicatorSeparator {
  display: none !important;
}

.css-tlfecz-indicatorContainer,
.css-tlfecz-indicatorContainer:hover {
  color: #092540 !important;
}

.css-14el2xx-placeholder,
.css-6j8wv5-Input,
.css-qc6sy-singleValue,
.css-6j8wv5-Input input {
  font-family: "Source Sans Pro" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #092540 !important;
}

.winner-auction-header {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 20px 50px;
  background-position: center !important;
  /* Center the image */
  background-repeat: no-repeat !important;
  /* Do not repeat the image */
  background-size: cover !important;
  /* Resize the background image to cover the entire container */
  /* headline-xl/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  /* or 125% */

  text-align: center;

  /* Base/White */

  color: #ffffff;
}

.winner-auction-body {
  /* text-md/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Secondary */

  color: #646566;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  padding: 15px 20px 20px 20px;
}

.auction-unit {
  display: flex;
  flex-direction: row;
  padding: 0px;

  /* Base/White */

  background: #ffffff;
  /* Light/40% */

  box-shadow: 0px 2px 4px rgba(61, 75, 92, 0.12),
    0px 0px 1px rgba(61, 75, 92, 0.05);
  border-radius: 12px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.auction-unit-image {
  background-position: center !important;
  /* Center the image */
  background-repeat: no-repeat !important;
  /* Do not repeat the image */
  background-size: cover !important;
  min-height: 90px;
  width: 200px;
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
}

.auction-unit-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  /* Base/White */

  background: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.auction-unit-content .skew-label {
  padding: 2px 12px;
  /* text-base/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Base/White */

  color: #ffffff;
}

.auction-unit-info {
  padding: 12px;
  text-align: left;
}

.auction-unit-info .auction-unit-name {
  /* text-md/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  /* Base/Secondary */

  color: #646566;
  text-transform: uppercase;
}

.auction-unit-info .auction-unit-price {
  /* text-lg/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  /* Base/Primary */

  color: #1e1e1f;
}

.margin-175-auto {
  margin: 1.75rem auto;
}

.live-auction .title {
  /* headline-md/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  text-align: center;

  /* Base/Primary */

  color: #1e1e1f;
}

.live-auction .subtitle {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Base/Secondary */

  color: #646566;
}

.page-not-found .title {
  /* headline-md/Bold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  /* Base/White */

  color: #ffffff;
}

.page-not-found .description {
  /* text-lg/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* or 156% */

  /* Base/Light */

  color: #e6e8eb;
}

.overlay-notfound {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #1e1e1f 0%, rgba(30, 30, 31, 0) 100%);
  mix-blend-mode: multiply;
  opacity: 0.5;
}

.btn-primary-caready {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Base/White */

  color: #ffffff;
}

/* the slides */
.auction-list .slick-slide {
  padding: 10px;
}

/* the parent */
.auction-list .slick-list {
  margin: 0 -10px;
}

.alert-caready.warning {
  background-color: #fee567;
  /* text-md/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 0.87rem;
  line-height: 20px;
  width: 100%;
  /* identical to box height, or 143% */
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  /* Base/Primary */

  color: #1e1e1f;
}

.location-info {
  flex: 1;
  margin-left: 20px;
}

.transaction-detail.detail-unit-slider {
  max-height: 220px !important;
}

.home-filter {
  background-color: #fff !important;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-top: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  height: 100% !important;
}

.home-filter-titip-lelang {
  background-color: #fff !important;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-top: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  height: 100% !important;
}

.no-padding {
  padding: 0px !important;
}

.home-filter-price {
  overflow-x: auto;
}

.tag-price {
  min-width: 150px;
}

.home-filter-no-padding {
  padding-right: 0px !important;
}

.home-filter-titip-lelang-no-padding {
  padding-left: 0px !important;
}

.button-titip-lelang {
  margin-top: 48px;
}

.titip-lelang-form {
  margin: auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.container-home-filter {
  margin-top: -4rem;
}

.about-caready {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.about-content {
  padding-bottom: 2rem !important;
  padding-top: 1rem !important;
  margin-bottom: 2rem !important;
}

.select-tipe-lelang {
  display: none !important;
}

.main-container {
  margin-bottom: 70px !important;
}

.mobile-footer-unit {
  display: none !important;
}

.w-100-md-auto {
  width: auto;
  gap: 0.5rem;
}

.jadwal-lelang-option {
  width: 174px;
}

.nav.tab-navigation {
  width: 100%;
  flex-wrap: nowrap !important;
  overflow-x: auto !important;
}

.nav.tab-navigation .nav-item {
  flex-wrap: nowrap !important;
  min-width: 150px !important;
  height: 43px !important;
}

.nav.tab-navigation .nav-item button {
  width: 100% !important;
}

.header-icon {
  height: 4rem;
}

.main-content {
  margin-top: 116px;
}

#custom-switch {
  font-size: 1.5rem;
}

.overview {
  background-color: #ffffff;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.visi-misi-item {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.image-overview {
  width: 100% !important;
}

.tentang-caready {
  background-color: #ffffff;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.tentang-caready .title {
  margin-top: 2rem;
}

.layanan-caready {
  background-color: #ffffff;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.filter-schedule {
  justify-content: space-between;
}

.btn-payment-history {
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  /* text-base/Semibold */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Base/Primary */

  color: #092540;
  /* Base/White */

  background: #ffffff;
  /* Base/Outline */

  border: 1px solid #dcdde0;
  border-radius: 8px;
  padding: 1rem;
}

.table-caready {
  /* Base/Outline */

  /* border: 1px solid #DCDDE0; */
}

.table-caready thead {
  /* Base/Outline */

  /* border: 1px solid #DCDDE0; */
}

.table-caready thead th {
  background: #e6e8eb;
  /* text-md/Semibold */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Secondary */

  color: #646566;
}

.table-caready thead th:first-child {
  border-top-left-radius: 8px;
}

.table-caready thead th:last-child {
  border-top-right-radius: 8px;
}

/* .table-caready tbody{
    border: 1px solid #DCDDE0;
} */

.table-caready td {
  /* text-md/Regular */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/Primary */

  color: #1e1e1f;
}

.table-caready td:nth-child(event) {
  /* Base/Surface */

  background: #f5f6f7 !important;
}

.homeBanner {
  /* height: 700px; */
  height: auto;
}

/* Responsive Elements */

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .card-unit-name {
    height: auto;
  }
  .is-sticky {
    top: 92px;
  }

  .card-unit-tag {
    width: 100%;
  }

  .card-unit .card-unit-body .card-unit-tag {
    margin-top: 0px;
  }

  .card-unit-tag > span {
    font-size: 8px;
    line-height: 12px;
  }

  .homeBanner {
    height: 100%;
  }

  .beli-nipl {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .type-unit-checkbox {
    margin-bottom: 0.5rem;
  }

  .modal-transaction {
    width: 90%;
  }

  .button-next-step {
    margin-top: 1rem;
  }

  .form-auth .p-5 {
    padding: 1.5rem !important;
  }

  .form-auth .px-5 {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }

  .has-line:before,
  .has-line:after {
    background-color: rgba(108, 117, 125) !important;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    margin: 0 5px;
    width: 25%;
  }

  .S9gUrf-YoZ4jf iframe {
    width: 100% !important;
    position: relative !important;
  }

  .button-responsive {
    width: 100%;
  }

  .image-preview {
    width: 100%;
  }

  .tagihan-gap {
    gap: 0.75rem;
  }

  .prosedur-arrow {
    display: none;
  }

  .image-item-procedure {
    width: 80px;
    height: 80px;
  }

  .logo-auth {
    margin-bottom: 3rem !important;
  }

  .location-tab:hover,
  .location-tab-active {
    border: none !important;
    cursor: pointer;
  }

  .location-info {
    margin-left: 0px !important;
    /* text-base/Regular */
  }

  .button-location {
    padding: 20px;
  }

  .fav-img-container {
    height: 222px;
  }

  .card-transaksi .unit-image {
    position: relative;
    background-position: center !important;
    /* Center the image */
    background-repeat: no-repeat !important;
    /* Do not repeat the image */
    background-size: cover !important;
    /* Resize the background image to cover the entire container */
    background: #f0f2f5;
    border-radius: 12px;
    width: 65px;
    height: 48px;
    margin-right: 10px;
  }

  .card-transaksi .info-unit {
    border-right: none !important;
    border-bottom: 1px solid #bebfc2 !important;
    padding-bottom: 10px !important;
  }

  .card-transaksi .header .value {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;

    /* Base/Secondary */
    color: #646566;
  }

  .card-transaksi .header .value.invoice-number {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;

    /* Base/Secondary */
    color: #646566;
  }

  .card-transaksi .info-tagihan,
  .card-transaksi .info-tagihan .button {
    margin-top: 10px;
  }

  .transaction-detail.detail-unit-slider {
    max-height: 220px !important;
  }

  .summary-transaction {
    margin-top: 0rem;
  }

  .home-filter {
    background-color: #fff !important;
    border-radius: 1rem;

    border: 1px solid #dee2e6;
    height: 100% !important;
  }

  .home-filter-titip-lelang {
    border-radius: 1rem;

    border: 1px solid #dee2e6;
  }

  .home-filter-no-padding {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
  }

  .home-filter-titip-lelang-no-padding {
    padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
  }

  .titip-lelang {
    padding: 2.5rem 1rem !important;
  }

  .bullet-titip-lelang {
    flex-direction: column !important;
  }

  .pack {
    align-items: center !important;
    justify-content: space-between !important;
    width: 100% !important;
    float: left !important;
  }

  .pack .image-circle {
    width: 50px !important;
    height: 50px !important;
    margin-right: 1rem !important;
    float: left !important;
  }

  .pack div.text-bullet {
    position: relative;
    float: left !important;
    margin-top: 10px !important;
  }

  .button-titip-lelang {
    grid-gap: 16px !important;
    gap: 16px !important;
    margin-top: 1rem !important;
  }

  .text-footer {
    text-align: center !important;
  }

  .titip-lelang-form {
    margin-top: 0px !important;
    margin-bottom: 3rem !important;
  }

  .home-slider-item {
    border-radius: 0px !important;
    height: 155px !important;
  }

  .container-home-filter {
    margin-top: -1.25rem !important;
  }

  .home-slider-arrow {
    display: none !important;
  }

  .card-unit .card-unit-tag {
    margin-top: 1rem;
  }

  .card-unit .card-unit-header {
    height: 150px !important;
  }

  .card-unit .card-unit-footer {
    /* text-sm/Semibold */

    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 12px !important;
    line-height: 18px !important;
    flex-direction: column !important;
  }

  .card-unit .metode {
    float: left !important;
    margin-top: 0.5rem !important;
  }

  .ads-title {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 23px !important;
  }

  .ads-link {
    display: block !important;
    /* Caption 12/Semibold */

    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    /* identical to box height, or 150% */

    text-align: center !important;
  }

  .about-title {
    margin-top: 1rem !important;
  }

  .auction-list .slider-arrow {
    display: none !important;
  }

  .home-lihat-selengkapnya {
    width: 100% !important;
    /* text-md/Semibold */

    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    text-align: center;

    /* Base/Primary */

    color: #1e1e1f;
  }

  .about-caready {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .about-content {
    padding-bottom: 1rem !important;
    padding-top: 0.5rem !important;
    margin-bottom: 1rem !important;
  }

  .tab-tipe-lelang {
    display: none !important;
  }

  .select-tipe-lelang {
    margin-top: 1rem !important;
    display: block !important;
  }

  .detail-unit.detail-unit-slider {
    max-height: 250px !important;
  }

  .unit-footer {
    z-index: 2;
    height: 60px !important;
    padding: 8px 16px !important;
  }

  .main-container {
    margin-bottom: 60px !important;
  }

  .unit-image-button {
    bottom: -60px !important;
    left: 0px !important;
    width: 50% !important;
    text-align: center !important;
  }

  .unit-container {
    margin-top: 45px !important;
  }

  .image-inspeksi {
    float: left !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .button-footer-unit.live-auction {
    display: block !important;
    padding: 8px 16px !important;

    /* Primary/Main */

    background: #c2000d;
    /* Light/20% */

    box-shadow: 0px 0.5px 2px rgba(61, 75, 92, 0.12),
      0px 0px 1px rgba(61, 75, 92, 0.05);
    border-radius: 8px;
    width: 100%;
  }

  .button-footer-unit.more-fill {
    padding: 8px;
    gap: 8px;

    width: 36px;
    height: 36px;

    /* Base/White */

    background: #ffffff;
    /* Base/Inline */

    border: 1px solid #bebfc2;
    border-radius: 8px;
  }

  .more-fill-menu {
    border-radius: 12px;
    padding: 10px;
    background-color: #ffffff !important;
    position: absolute;
    display: flex;
    flex-direction: column !important;
    top: -190px !important;
    right: 20px !important;
    z-index: 2;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
  }

  .more-fill-menu div {
    padding: 8px 12px;
  }

  .slick-dots {
    text-align: center !important;
  }

  .slider-arrow {
    display: none !important;
  }

  .mobile-footer-unit {
    display: flex !important;
  }

  .auction-unit {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .auction-unit-info {
    text-align: center;
  }

  .auction-unit-image {
    border-radius: 12px;
  }

  .w-100-md-auto {
    width: 100%;
  }

  .jadwal-lelang-option {
    width: 100%;
  }

  .titip-lelang-title {
    /* headline-lg/Bold */

    font-family: "Source Sans Pro" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 28px !important;
    line-height: 36px !important;
    /* or 129% */

    text-align: center !important;

    /* Base/White */

    color: #ffffff !important;
    text-align: center !important;
    margin-bottom: 0px !important;
  }

  .tahapan-lelang {
    padding-top: 3rem;
    padding-bottom: 0rem !important;
  }

  .counter {
    text-align: center !important;
  }

  .counter-item {
    margin-bottom: 1.5rem !important;
  }

  .button-login-titip-lelang {
    margin-top: 2rem;
  }

  .inspeksi-slider {
    max-height: 220px !important;
    margin-bottom: 2rem !important;
  }

  .header-icon {
    height: 2rem;
  }

  .main-content {
    margin-top: 92px;
  }

  .com-modal-content {
    min-width: 300px;
  }

  .overview .title,
  .visi-misi .title,
  .tentang-caready .title,
  .layanan-caready .title {
    font-size: 24px !important;
  }

  .overview .desc,
  .visi-misi .desc,
  .tentang-caready .desc,
  .layanan-caready .desc {
    font-size: 14px !important;
  }

  .locations .location {
    font-size: 32px !important;
  }

  .overview {
    background-color: #ffffff;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .visi-misi-item {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .image-overview {
    width: 100% !important;
  }

  .tentang-caready {
    background-color: #ffffff;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .tentang-caready .title {
    margin-top: 2rem;
  }

  .layanan-caready {
    background-color: #ffffff;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .services .title {
    font-size: 18px !important;
  }

  .login-facebook,
  .login-google {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .login-google {
    /* margin-left: 68px !important; */
  }

  #buttonGoogle {
    width: 200px;
  }

  .custom-facebook {
    width: 200px !important;
  }

  .filter-schedule {
    justify-content: space-between;
    flex-direction: column !important;
    margin-bottom: 1rem;
  }

  .title-schedule-section {
    margin-bottom: 0rem !important;
  }

  .informasi-unit-auction {
    flex: 0 50%;
  }
}

@media (max-width: 333px) {
  .is-sticky {
    top: 112px;
  }

  .card-unit-lot:after {
    border-top: 29px solid #cf1701;
    right: -14px;
  }
}
@media (max-width:480px) {
  .syarat-dan-ketentuan{
    font-size: 2.1rem;
  }
}
@media (max-width: 321px) {
  .syarat-dan-ketentuan {
    font-size: 1.7rem;
  }
}
@media (max-width: 268px) {
  .syarat-dan-ketentuan {
    font-size: 1.2rem;
  }
}
/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .beli-nipl {
    margin-top: 2.5rem;
  }
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

@media (min-width: 1200px) {
  .beli-nipl {
    margin-top: 5rem;
  }

  .modal-transaction {
    width: 50%;
  }

  .list-type-unit-checkbox {
    overflow-x: auto;
  }

  .has-line:before,
  .has-line:after {
    background-color: rgba(108, 117, 125) !important;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 33%;
    margin: 0 5px;
  }
}

.modal-width-90 {
  width: 85vw;    /* Occupy the 90% of the screen width */
  max-width: 85vw;
}

.cancelled-title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px
}
